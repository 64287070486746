import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 

import image1 from "../../assets/hero/b5.webp";
import image2 from "../../assets/hero/b7.avif";
import image3 from "../../assets/hero/b16.avif";
import image4 from "../../assets/hero/b20.avif";
import axios from "axios";

const Contact = () => {
  const [showPopup, setShowPopup] = useState(false);
  const[popupMessage,setPopupMessage]=useState(false);
  const[popupStatus,setPopupStatus]=useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowPopup(true); // Show popup immediately when form is submitted
  
    try {
      const response = await axios.post('http://localhost:4005/sendemail', formData); // Replace with your backend URL
      console.log(response.data.message); // Log success message from backend
      setPopupMessage('Email sent successfully!'); // Update popup message
      setPopupStatus('success'); // Set popup to success state
    } catch (error) {
      console.error('Error sending email:', error.response?.data?.message || error.message);
      setPopupMessage('Failed to send email. Please try again.'); // Update popup message
      setPopupStatus('error'); // Set popup to error state
    } finally {
      // Hide popup after 5 seconds
      setTimeout(() => {
        setShowPopup(false);
      }, 5000);
    }
  };

  const settings = {
    dots: true,  
    infinite: true,  
    speed: 500, 
    slidesToShow: 1,  
    slidesToScroll: 1,  
    autoplay: true,  
    autoplaySpeed: 2000,  
  };

  return (
    <div className="relative bg-gray-100 min-h-screen flex items-center justify-center py-9">
      {/* Popup */}
      {showPopup && (
        <div className="absolute h-36 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 bg-white shadow-lg rounded-lg p-6">
          <h3 className="text-lg font-bold text-orange-500 mb-2">Thank You !(❁´◡`❁)</h3>
          <p className="text-gray-600 text-xl">We will get back to you shortly.</p>
        </div>
      )}

      <div className="bg-white shadow-lg rounded-lg max-w-4xl w-full grid grid-cols-1 md:grid-cols-2 z-0">
        {/* Left Section with Contact Information */}
        <div className="bg-orange-500 text-white p-8 flex flex-col justify-between">
          <div>
            <h2 className="text-2xl font-bold mb-4">Contact Information</h2>
            <p className="text-sm mb-6">
              Fill up the form and our team will get back to you within 24
              hours.
            </p>
            <div className="space-y-4">
              <div className="flex items-center">
                <span className="material-icons text-xl mr-4">phone</span>
                <p className="text-sm">9700277740</p>
              </div>
              <div className="flex items-center">
                <span className="material-icons text-xl mr-4">email</span>
                <p className="text-sm">contactus@premdigitalsignage.com</p>
              </div>
            </div>
          </div>

          {/* Image Carousel */}
          <Slider {...settings} className="rounded-lg overflow-hidden mb-4 mt-6">
            <div>
              <img
                src={image1}
                alt="Slide 1"
                className="w-64"
              />
            </div>
            <div>
              <img
                src={image2}
                alt="Slide 2"
                className="w-64"
              />
            </div>
            <div>
              <img
                src={image3}
                alt="Slide 3"
                className="w-64"
              />
            </div>
            <div>
              <img
                src={image4}
                alt="Slide 4"
                className="w-64"
              />
            </div>
          </Slider>

          {/* Social Media Links */}
          <div className="mt-6 flex space-x-4">
            <a href="#" className="text-white text-lg">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="#" className="text-white text-lg">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#" className="text-white text-lg">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        {/* Right Section with Contact Form */}
        <div className="p-8">
          <h2 className="text-2xl font-bold mb-4 text-orange-500">Contact US</h2>
          <p className="text-sm mb-6 text-gray-700">
            Any Question or remarks? Just write us a message
          </p>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                placeholder="Your Name"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                placeholder="Your Email"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Phone
              </label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                placeholder="Your Phone Number"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Message
              </label>
              <textarea
                type="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Write your message"
                rows="2"
              
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-orange-500 text-white px-6 py-2 rounded-md hover:bg-orange-500"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
