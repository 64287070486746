import React from 'react';

const Footer = () => {
  return (
    <div className="bg-primary text-white">
      <footer className="text-center text-lg-start shadow-lg">
        <div className="container p-4 bg-orange-500 bg-opacity-90 rounded-lg shadow-md">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            
           
            <div className="col mb-4">
              <div className=" w-20 bg-white shadow-lg mx-auto p-4 mb-4">
                <img
                  src="./premdigitallogo.png"
                  alt="Logo"
                  className="h-14 w-24"
                />
              </div>
              <p className="text-center">
              Transforming spaces with vibrant signboards that communicate your message effortlessly.
              </p>
              <div className="flex justify-center space-x-4 mt-4">
                <a href="#!" className="text-white hover:text-gray-400">
                  <i className="fab fa-facebook-square"></i>
                </a>
                <a href="#!" className="text-white hover:text-gray-400">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="#!" className="text-white hover:text-gray-400">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>

  
            <div className="col mb-4">
              <h5 className="text-xl font-semibold mb-4">QuikLinks</h5>
              <ul className="list-none space-y-2">
                <li><a href="#!" className="text-white hover:text-gray-400">Home</a></li>
                <li><a href="#!" className="text-white hover:text-gray-400">About Us</a></li>
                <li><a href="#!" className="text-white hover:text-gray-400">Services</a></li>
                <li><a href="#!" className="text-white hover:text-gray-400">Gallery</a></li>
                <li><a href="#!" className="text-white hover:text-gray-400">Contact Us</a></li>
               
              </ul>
            </div>
            <div className="col mb-4">
              <h5 className="text-xl font-semibold mb-4">products</h5>
              <ul className="list-none space-y-2">
                <li><a href="#!" className="text-white hover:text-gray-400">ACP</a></li>
                <li><a href="#!" className="text-white hover:text-gray-400">Glow</a></li>
                <li><a href="#!" className="text-white hover:text-gray-400">Logo</a></li>
                <li><a href="#!" className="text-white hover:text-gray-400">Banners</a></li>
                <li><a href="#!" className="text-white hover:text-gray-400">Flex</a></li>
                <li><a href="#!" className="text-white hover:text-gray-400">LED</a></li>
              </ul>
            </div>

            
            <div className="col mb-4">
              <h5 className="text-xl  mr-16 font-semibold mb-4">Contact</h5>
              <ul className="list-none space-y-2">
                <li>
                  <p className="text-white ml-24 flex items-center">
                    <i className="fas fa-map-marker-alt mr-2"></i>Miyapur,Hyd
                  </p>
                </li>
                <li>
                  <p className="text-white ml-24 flex  items-center">
                    <i className="fas fa-phone mr-2"></i>9700277740
                  </p>
                </li>
                <li>
                  <p className="text-white ml-24  flex items-center">
                    <i className="fas fa-envelope mr-2"></i>contactus@premdigitalsignage.com
                  </p>
                </li>
              </ul>
            </div>

          </div>
        </div>
        <div className="text-center p-4 bg-gray-900">
        <p className="text-white">
          © {new Date().getFullYear()} Copyright : 
          <a href="" className="text-white hover:text-gray-400"> PremDigital </a> 
          | Powered by 
          <a href="" className="text-white hover:text-gray-400"> BOBROS </a>
        </p>

        </div>
      </footer> 
    </div>
  );
};

export default Footer;
