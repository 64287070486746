import React from "react";
import ACPImage from "../../assets/services/ONE.webp";
import GlowImage from "../../assets/services/Glow.webp";
import ThreeDImage from "../../assets/services/channel2.webp";
import Banners from "../../assets/services/banner.jpeg";
import flex from "../../assets/services/vinyl.webp";
import logo2 from "../../assets/services/cnc.webp";

const Services = () => {
  const services = [
    {
      id: 1,
      title: "Acrylic SignBoard",
      description: "High-quality custom signboards to meet your branding needs.",
      image: ACPImage,
      link: "#",
    },
    {
      id: 2,
      title: "Glow Boards",
      description: "Bright and engaging LED displays for indoor and outdoor use.",
      image: GlowImage,
      link: "#",
    },
    {
      id: 3,
      title: "Channel Letters",
      description: "Channel letter signs are 3D graphic signs that often include lighting for better visibility.",
      image: ThreeDImage,
      link: "#",
    },
    {
      id: 3,
      title: "Banners",
      description: " Banners are large printed displays used for advertising or promotions.",
      image: Banners,
      link: "#",
    },
    {
      id: 3,
      title: "VinylPrinting",
      description: "Vinyl printing is used to create durable and vibrant graphics on materials like stickers and signs.",
      image: flex,
      link: "#",
    },
    {
      id: 3,
      title: "Cnc",
      description: "CNC is a technology that uses computers to control machines for precise cutting and shaping of materials.",
      image: logo2,
      link: "#",
    },
  ];

  return (
    <div className="bg-gradient-to-r from-gray-100 to-gray-200 flex flex-col justify-center items-center p-6">
      {/* Heading and Subtitle */}
      <div className="text-center mb-8">
        <h2 className="text-4xl font-bold text-orange-600 mb-4">Our Services</h2>
        <h3 className="text-small text-gray-600">
          Discover the range of professional services we offer to elevate your brand.
        </h3>
      </div>

      {/* Cards Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-24">
        {services.map((service) => (
          <div key={service.id} className="card bg-white rounded-lg w-64 shadow-md mx-auto">
            <img
              src={service.image}
              className="card-img-top rounded-t-lg w-full h-40 object-cover"
              alt={service.title}
            />
            <div className="card-body p-4">
              <h5 className="card-title text-xl font-bold">{service.title}</h5>
              <p className="card-text text-gray-700">{service.description}</p>
              <a
                href={service.link}
                className="btn btn-primary mt-4 inline-block px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-blue-600"
              >
                Learn More
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
