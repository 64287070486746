import React from 'react';
import { list } from '../../data/Data'; 
import { Link } from 'react-router-dom';

const Gallery = () => {
  
  const filteredList = list.filter(
    (image) => image.id === 1 || image.id === 2 || image.id === 3 || image.id === 4 || image.id === 5 || image.id === 6
  );

  return (
    <div className="bg-gradient-to-r from-gray-300 to-gray-500 flex flex-col items-center justify-center mb-[55px]">
    
      <h2 className="text-4xl font-bold text-gray-800 mt-16 mb-8">Our Gallery</h2>
      <div className="grid gap-[55px] grid-cols-3 justify-center m-[55px]">
        {filteredList.map((image, index) => (
          <a
            href="#"
            className="relative block overflow-hidden rounded-lg shadow-md transition-all duration-750 ease-in-out group"
            key={index}
          >
            <img
              src={image.cover} 
              alt={image.location} 
              className="w-72 h-full object-cover transition-transform duration-750 group-hover:scale-110"
            />
            <span className="absolute inset-0 bg-black bg-opacity-70 transform translate-y-full transition-transform duration-750 group-hover:translate-y-0 z-1"></span>
            <span className="absolute bottom-[10%] left-1/2 transform -translate-x-1/2 w-full text-center text-white text-2xl font-bold opacity-0 transition-opacity duration-750 group-hover:opacity-100 z-2">
              {image.category} 
            </span>

           <Link to='/gallery'>
           <button className="absolute bottom-20 left-1/2 transform -translate-x-1/2 w-56 text-center text-white bg-orange-500 py-2 px-4 rounded-xl opacity-0 transition-opacity duration-750 group-hover:opacity-100 z-2">
              Gallery
            </button>
           </Link>
           </a>
        ))}
      </div>
      <Link to="/gallery">
      <button className="text-white bg-orange-500 py-2 px-4 rounded-xl mt-8 mb-10 hover:bg-orange-600 transition-colors">
        Explore All
      </button>
      </Link>
     
    </div>
  );
};

export default Gallery;
