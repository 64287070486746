export const nav = [
    {
      text: "home",
      path: "/",
    },
    {
      text: "about",
      path: "/about",
    },
    {
      text: "services",
      path: "/services",
    },
    {
        text: "gallery",
        path: "/gallery",
      },
  
    {
      text: "contact",
      path: "/contact",
    },
  ]

export const list = [
    {
      id: 1,
      cover: "../images/list/GlowSignBoard1.jpg",
     
      location: "Hyderabad",
      category: "GlowSignBoard",
   
      
    },
    {
      id: 2,
      cover: "../images/list/Acrylic.jpg",
    
      location: "Hyderabad",
      category: "Acrylic",
      
     
    },
    {
      id: 3,
      cover: "../images/list/Acrylic2.jpg",
      location: "Hyderabad",
      category: "Acrylic",
     
     
    },
    {
      id: 4,
      cover: "../images/list/Acrylic3.jpg",
      location: "Hyderabad",
      category: "Acrylic",
     
      
    },
    {
      id: 5,
      cover: "../images/list/Acrylic4.jpg",
      name: "Brick Lane Realty",
      location: "Hyderabad",
      category: "Acrylic",
    
     
    },
    {
      id: 6,
      cover: "../images/list/GlowSignBoard2.jpg",
  
      location: "Hyderabad",
      category: "GlowSignBoard",
     
    
    },
    {
      id: 7,
      cover: "../images/list/b9.avif",
  
      location: "Hyderabad",
      category: "3D",
     
    
    },
    {
      id: 8,
      cover: "../images/list/b10.avif",
  
      location: "Hyderabad",
      category: "3D",
   
    },
    {
      id: 9,
      cover: "../images/list/b23.avif",
  
      location: "Hyderabad",
      category: "LED SignBoard",
   
    
    },
    {
      id: 10,
      cover: "../images/list/b14.avif",
  
      location: "Hyderabad",
      category: "3D",
    
    
    },
    {
      id: 11,
      cover: "../images/list/b15.avif",
  
      location: "Hyderabad",
      category: "3D",
    
    
    },
    {
      id: 12,
      cover: "../images/list/b13.avif",
  
      location: "Hyderabad",
      category: "3D",
  
    
    },
    {
      id: 13,
      cover: "../images/list/b16.avif",
  
      location: "Hyderabad",
      category: "3D",
     
    
    },
    {
      id: 14,
      cover: "../images/list/b17.avif",
  
      location: "Hyderabad",
      category: "3D",
   
    
    },
    {
      id: 15,
      cover: "../images/list/b18.avif",
  
      location: "Hyderabad",
      category: "3D",
   
    
    },
    {
      id: 16,
      cover: "../images/list/b19.webp",
  
      location: "Hyderabad",
      category: "3D",
    
    
    },
    {
      id: 17,
      cover: "../images/list/board3.avif",
  
      location: "Hyderabad",
      category: "3D",
    
    
    },
    {
      id: 18,
      cover: "../images/list/b24.jpg",
  
      location: "Hyderabad",
      category: "3D",
      
    
    },
    {
      id: 19,
      cover: "../images/list/b25.avif",
  
      location: "Hyderabad",
      category: "3D",
  
    
    },
    {
      id: 20,
      cover: "../images/list/b27.avif",
  
      location: "Hyderabad",
      category: "3D",
     
    
    },
    {
      id: 21,
      cover: "../images/list/b29.webp",
  
      location: "Hyderabad",
      category: "3D",
      
    
    },
    {
      id: 22,
      cover: "../images/list/b30.avif",
  
      location: "Hyderabad",
      category: "3D",
     
    
    },
    {
      id: 23,
      cover: "../images/list/b31.avif",
  
      location: "Hyderabad",
      category: "3D",
     
    
    },
    {
      id: 24,
      cover: "../images/list/b32.webp",
  
      location: "Hyderabad",
      category: "3D",
      
    
    },
    {
      id: 25,
      cover: "../images/list/b33.avif",
  
      location: "Hyderabad",
      category: "3D",
      
    
    },
    {
      id: 26,
      cover: "../images/list/b26.avif",
  
      location: "Hyderabad",
      category: "3D",
    
    
    },
    {
      id: 27,
      cover: "../images/list/b28.avif",
  
      location: "Hyderabad",
      category: "3D",
     
    
    },
    {
      id: 28,
      cover: "../images/list/s1.avif",
  
      location: "Hyderabad",
      category: "3D",
     
    
    },
    {
      id: 29,
      cover: "../images/list/s2.avif",
  
      location: "Hyderabad",
      category: "3D",
     
    
    },
    {
      id: 30,
      cover: "../images/list/s3.avif",
  
      location: "Hyderabad",
      category: "3D",
     
    
    },
    {
      id: 31,
      cover: "../images/list/s4.avif",
  
      location: "Hyderabad",
      category: "3D",
     
    
    },
    {
      id: 32,
      cover: "../images/list/s5.avif",
  
      location: "Hyderabad",
      category: "3D",
     
    
    },
    {
      id: 33,
      cover: "../images/list/s6.avif",
  
      location: "Hyderabad",
      category: "3D",
     
    
    },
    {
      id: 34,
      cover: "../images/list/s7.avif",
  
      location: "Hyderabad",
      category: "3D",
     
    
    },
    {
      id: 35,
      cover: "../images/list/s8.avif",
  
      location: "Hyderabad",
      category: "3D",
     
    
    },
    {
      id: 36,
      cover: "../images/list/s9.avif",
  
      location: "Hyderabad",
      category: "3D",
     
    
    },
    {
      id: 37,
      cover: "../images/list/s10.avif",
  
      location: "Glow SignBoard",
      category: "3D",
     
    
    },
    {
      id: 38,
      cover: "../images/list/s11.avif",
  
      location: "Hyderabad",
      category: "LED SignBoard",
     
    
    },
    {
      id: 39,
      cover: "../images/list/s18.avif",
  
      location: "Hyderabad",
      category: "Glow SignBoard",
     
    
    },
  ]