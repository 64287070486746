import React from 'react';
import image1 from "../../assets/about/p15.png";
import image2 from "../../assets/about/prem5.png";
import "./about.css";


const AboutUs = () => {
  return (
    <section className="p-4 sm:p-8 bg-gradient-to-r from-white to-blue-100  text-black rounded-lg min-h-screen shadow-md">
      <div className="flex flex-col lg:flex-row gap-8">
  
        <div
          className="lg:w-1/2 ml-[-60] animate-fade-in-up"
          style={{ animation: "fade-in-up 3s ease-in-out" }}
        >
          <h2 className="text-3xl sm:text-4xl font-extrabold text-center mb-4 text-gray-800">
            About <span className="text-orange-500">PremDigitals</span>
          </h2>
          <h6 className="text-lg sm:text-2xl font-semibold text-center text-gray-700">
            Signboards - The art of making first impressions last forever.
          </h6>
          <p className="text-gray-700 text-justify max-w-full lg:max-w-2xl mt-6 mx-auto leading-relaxed">
            At PremDigitals, we pride ourselves on being a one-stop solution for all your signboard and branding needs.
            Our mission is to help businesses and individuals stand out with visually stunning and innovative designs 
            that not only grab attention but also leave a lasting impression.
          </p>
        </div>
          <div className="lg:w-1/2 relative left-40 mt-6 lg:mt-0 flex justify-center items-center">
          <img
            src={image2}
            alt="First Image"
            className="absolute top-0 left-12 w-max sm:w-[500px] h-[500px] sm:h-[500px] hidden sm:block"
 
            />
      
        </div>

      </div>

      <div className="mt-8 space-y-6 sm:mx-6 max-w-full sm:max-w-2xl animate-fade-in-up" style={{ animation: "fade-in-up 3s ease-in-out" }}>
        <p className="text-gray-700">
          Prem Digitals, a leading manufacturer of LED and Flex Boards in Miyapur, Hyderabad, has been successfully catering to customer demands since its inception in 2017. 
          Known for its exceptional quality and service, the business has built a strong reputation in the field.
        </p>
        <p className="text-gray-700 leading-relaxed">
          Conveniently located at House No. 1-248, Opposite IndusInd Bank, Above Apollo Pharmacy, Near X Road, Miyapur-500049,
          this establishment is easy to find. Its proximity to prominent landmarks such as IndusInd Bank and Apollo Pharmacy makes it highly accessible.
        </p>
 

        <h3 className="text-xl sm:text-2xl font-semibold text-gray-800">Our Achievements</h3>
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Over 300 positive reviews, reflecting customer satisfaction.</li>
          <li>Recognized with stamps like JD Verified, JD Trusted, and JD Escrow, substantiating our credibility.</li>
          <li>Committed to delivering a positive and seamless customer experience.</li>
        </ul>

        <h3 className="text-xl sm:text-2xl font-semibold text-gray-800">Our Expertise in Flex Printing</h3>
        <p className="text-gray-700 leading-relaxed">
          Flex printing involves premium digital banner printing using high-quality industrial presses. 
          We also offer alternatives such as star flex, which provides a shiny, reflective surface for enhanced visual appeal.
        </p>
        <p className="text-gray-700 leading-relaxed">
          In today’s competitive world, effective marketing is essential for businesses of all sizes. 
          Flex Printing Services in Miyapur, Hyderabad remain popular for printing images and product descriptions on materials like plastic, 
          paper, banners, hoardings, and packaging, even in this digital age.
        </p>

        <h3 className="text-xl sm:text-2xl font-semibold text-gray-800">Signboard Design Excellence</h3>
        <p className="text-gray-700 leading-relaxed">
          Signboards are more than just tools; they create a strong visual impact that instantly captures attention.
          Acting as digital front doors, they showcase a brand’s personality, message, and offerings in a compelling way.
        </p>
        <p className="text-gray-700 leading-relaxed">
          With <b>bold, eye-catching designs</b>, our signboards communicate essential information—whether it’s a business 
          name, tagline, or key details—ensuring your brand stands out.
        </p>
      </div>
    
    </section>
  );
};

export default AboutUs;
