import React, { useState } from "react";
import { Link } from "react-router-dom";
import { nav } from "../../data/Data";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const toggleServicesDropdown = () =>
    setIsServicesDropdownOpen(!isServicesDropdownOpen);

  const contactInfo = [
    {
      icon: "fa-phone-alt",
      text: "9700277740",
    },
    {
      icon: "fa-envelope",
      text: "contactus@premdigitalsignage.com",
    },
    {
      icon: "fa-map-marker-alt",
      text: "Miyapur, HYD",
    },
  ];

  const servicesDropdown = [
    { text: "Acrylic Close LED Board", path: "/services/web-design" },
    { text: "Acrylic Open  LED Board", path: "/services/digital-marketing" },
    { text: "CNc Cutting Acrylic Board", path: "/services/content-creation" },
    { text: "Acrylic Channel Letters", path: "/services/content-creation" },
    { text: "Acrylic Liquid Letters", path: "/services/content-creation" },
    { text: "Aluminium Standees", path: "/services/content-creation" },
    { text: "Glow Sign Boards", path: "/services/content-creation" },
    { text: "Acrylic Sandwich Boards", path: "/services/content-creation" },
    { text: "Business Cards", path: "/services/content-creation" },
    { text: "Phamplets/Brouchers/ Envelop Covers", path: "/services/content-creation" },
  ];

  return (
    <nav className="bg-gradient-to-r from-gray-800 to-gray-500 sticky top-0 z-50 text-black shadow-md">
      {/* Top Section: Contact Information */}
      <div className="bg-orange-400 py-2 hidden md:block">
        <div className="container mx-auto px-4 flex justify-end items-center">
          <div className="flex space-x-6">
            {contactInfo.map((info, index) => (
              <div className="flex items-center space-x-2" key={index}>
                <i className={`fa-solid ${info.icon} text-white`}></i>
                <span className="text-sm text-white">{info.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Main Navbar */}
      <div className="container mx-auto px-4 py-0 flex items-center justify-between">
        {/* Logo Section */}
        <div className="flex ml-6 items-center space-x-3">
          <img src="premdigitallogo.png" alt="Logo" className="w-24 h-24" />
          <div className="text-4xl text-white font-bold">
            Prem<span className="text-orange-500">Digital</span>
          </div>
        </div>

        {/* Desktop Navigation Links */}
        <ul
          className={`md:flex space-x-12 ${
            isMobileMenuOpen ? "hidden" : "block"
          } hidden md:block`}
        >
          {nav.map((item, index) =>
            item.text === "services" ? (
              <li
              key={index}
              className="relative group"
              onMouseEnter={() => setIsServicesDropdownOpen(true)}
              onMouseLeave={() => setIsServicesDropdownOpen(false)}
            >
              <span className="text-orange-400 hover:text-yellow-400 capitalize cursor-pointer flex items-center">
                {item.text}
                <i className="ml-2 fa-solid fa-chevron-down text-sm"></i>
              </span>
              {/* Dropdown */}
              {isServicesDropdownOpen && (
                <ul
                  className="absolute left-0 mt-0 bg-white border border-orange-500 rounded shadow-md w-56 z-10"
                >
                  {servicesDropdown.map((service, idx) => (
                    <li key={idx}>
                      <Link
                        to={service.path}
                        className="block px-4 py-2 text-black hover:bg-gray-200"
                      >
                        {service.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
            
            ) : (
              <li key={index}>
                <Link
                  to={item.path}
                  className="text-white hover:text-yellow-400 capitalize"
                >
                  {item.text}
                </Link>
              </li>
            )
          )}
        </ul>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden text-2xl focus:outline-none"
          onClick={toggleMobileMenu}
        >
          <i className="fa-solid fa-bars"></i>
        </button>
      </div>

      {/* Off-canvas Mobile Menu */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-50 md:hidden"
          onClick={toggleMobileMenu}
        >
          <div className="fixed top-0 right-0 w-64 bg-white h-full p-6">
            <button
              className="text-xl text-black mb-6"
              onClick={toggleMobileMenu}
            >
              <i className="fa-solid fa-times"></i> Close
            </button>
            <ul className="space-y-6">
              {nav.map((item, index) =>
                item.text === "services" ? (
                  <li key={index} className="relative">
                    <span
                      className="text-black hover:text-yellow-400 capitalize cursor-pointer"
                      onClick={toggleServicesDropdown}
                    >
                      {item.text}
                    </span>
                    {/* Dropdown for Mobile */}
                    {isServicesDropdownOpen && (
                      <ul className="mt-2 bg-white border border-gray-200 rounded shadow-md">
                        {servicesDropdown.map((service, idx) => (
                          <li key={idx}>
                            <Link
                              to={service.path}
                              className="block px-4 py-2 text-black hover:bg-gray-200"
                              onClick={toggleMobileMenu}
                            >
                              {service.text}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ) : (
                  <li key={index}>
                    <Link
                      to={item.path}
                      className="text-black hover:text-yellow-400 capitalize"
                      onClick={toggleMobileMenu}
                    >
                      {item.text}
                    </Link>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
