import React from "react";
import { Link } from "react-router-dom";

const About = () => (
  <section className="p-8 bg-gradient-to-r from-gray-100 to-gray-300 rounded-lg shadow-md flex items-center justify-center">
    <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between w-full lg:max-w-5xl">
      {/* Left Content */}
      <div className="lg:w-1/2 px-4">
        <h2 className="text-4xl font-bold mb-4 text-orange-600">
          About <span className="text-gray-800">PremDigital</span>
        </h2>
        <h6 className="text-2xl font-semibold mb-6 text-gray-700">
          Signboards - The art of making first impressions last forever.
        </h6>
        <div className="text-container space-y-4">
          <p className="text-lg text-gray-800 leading-relaxed">
            At PremDigitals, we pride ourselves on being a one-stop solution for all your signboard and branding needs. 
            Our mission is to help businesses and individuals stand out with visually stunning and innovative designs 
            that not only grab attention but also leave a lasting impression.
          </p>
          <p className="text-lg text-gray-800 leading-relaxed">
            We combine craftsmanship, technology, and creativity to deliver solutions that elevate your brand. 
            Whether it’s for indoor displays, outdoor advertising, or special events, we are here to make your vision shine.
          </p>
        </div>
        <div className="mt-6 flex justify-start">
          <Link to="/aboutus">
            <button className="px-6 py-3 bg-orange-400 text-white font-semibold rounded-lg hover:bg-orange-500">
              Learn More
            </button>
          </Link>
        </div>
      </div>

      {/* Right Image */}
      <div className="lg:w-1/2 mt-6 lg:mt-0 flex  ml-20 justify-center">
        <img
          src="./about.png"
          alt="About PremDigital"
          className="rounded-lg shadow-lg max-w-full sm:ml-3"
        />
      </div>
    </div>
  </section>
);

export default About;
